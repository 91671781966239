import React from "react";
import PG_AP_PARTNERS_ARE_CONTENT from "./index.content";
import "./index.scss";

const PartnersAre = () => {
  return (
    <section className="PgAP-PartnersAre">
      <div className="container">
        <h2 className="PgAP-PartnersAre__title">Our Partners Are</h2>
        <ul className="PgAP-PartnersAre__content">
          {PG_AP_PARTNERS_ARE_CONTENT.map(({ SVGImg, title, text }, i) => (
            <li key={i} className="PgAP-PartnersAre-item">
              <img src={SVGImg} alt="img" />
              <p className="PgAP-PartnersAre-item__title">{title}</p>
              <p className="PgAP-PartnersAre-item__text">{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PartnersAre;
