import React from "react";
import PG_AP_AFFILIATE_APP_CONTENT from "./index.content";
import Screenshot from "../Screenshot";
import "./index.scss";

const AffiliateApp = () => {
  return (
    <section className="PgAP-AffiliateApp">
      <div className="container">
        <h2 className="PgAP-AffiliateApp__title">Dedicated affiliate app</h2>
        <div className="PgAP-AffiliateApp__content">
          <Screenshot
            listPictures={PG_AP_AFFILIATE_APP_CONTENT}
            classes="PgAP-AffiliateApp__screenshot"
          />
          <div className="PgAP-AffiliateApp__info PgAP-AffiliateApp-info">
            <p className="PgAP-AffiliateApp-info__text">
              We use iDevAffiliate, the leading affiliate tracking software, to
              help you create custom tracking links, analyze traffic, and get
              real-time data on conversions and earnings.
            </p>
            <p className="PgAP-AffiliateApp-info__text">
              You'll have your own iDevAffiliate account to monitor the
              performance anytime.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AffiliateApp;
