import React, { useEffect, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import Intro from "../components/affiliate-program/Intro";
// import Paid from "../components/affiliate-program/Paid";
// import TOPPartners from "../components/affiliate-program/TOPPartners";
import PartnersAre from "../components/affiliate-program/PartnersAre";
import Software from "../components/affiliate-program/Software";
import Work from "../components/affiliate-program/Work";
import AffiliateApp from "../components/affiliate-program/AffiliateApp";
import StartMaking from "../components/affiliate-program/StartMaking";
import Testimonials from "../components/affiliate-program/Testimonials";

const PgAP = () => {
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      customClass="PgAP"
    >
      <Seo
        title="Join AMZScout Affiliate Program"
        description="Looking to generate an additional revenue source? Join the AMZScout affiliate program to get 30% from every order made by customers you refer"
        page="affiliate-program"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <Intro />
      {/* <Paid />*/}
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
      {/* <TOPPartners />*/}
      <PartnersAre />
      <Software />
      <Work />
      <AffiliateApp />
      <StartMaking />
    </Layout>
  );
};

export default PgAP;
