import React from "react";
import PG_AP_WORK_CONTENT from "./index.content";
import "./index.scss";

const Work = () => {
  return (
    <section className="PgAP-Work">
      <div className="container">
        <h2 className="PgAP-Work__title">How our affiliate program works</h2>
        <p className="PgAP-Work__subTitle">
          AMZScout is one of the top tools for Amazon sellers worldwide, with
          customers ranging from first time sellers to established companies
          like RedBull, Disney, and CASIO
        </p>
        <ul className="PgAP-Work__content">
          {PG_AP_WORK_CONTENT.map(({ title, subTitle }, i) => (
            <li key={i} className="PgAP-Work-item">
              <p className="PgAP-Work-item__title">{title}</p>
              <p className="PgAP-Work-item__subTitle">{subTitle}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Work;
