import SVGPointIcon from "../../../assets/images/vector/components/common/group-copy.svg"
import SVGImg from "../../../assets/images/vector/pages/affiliate-program/Intro/affiliate-program-Intro.svg"

const PG_AP_INTRO_CONTENT = {
  dataLink: {
    url: "https://amzscout.idevaffiliate.com",
    text: "Join AMZScout Affiliate Program",
    category: "AffiliateProgramLP",
  },
  SVGPointIcon,
  SVGImg,
}
export default PG_AP_INTRO_CONTENT
