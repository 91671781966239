import React from "react";
import PG_AP_START_MAKING_CONTENT from "./index.content";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const StartMaking = () => {
  const { text, url, category } = PG_AP_START_MAKING_CONTENT;

  return (
    <section className="PgAP-StartMaking">
      <div className="container">
        <h2 className="PgAP-StartMaking__title">
          Start making up to $10,000/mo with AMZScout!
        </h2>
        <TrackedLink
          category={category}
          action={text}
          class="PgAP-StartMaking__link"
          path={url}
        >
          {text}
        </TrackedLink>
      </div>
    </section>
  );
};

export default StartMaking;
