import React from "react";
import PG_AP_SOFTWARE_CONTENT from "./index.content";
import Screenshot from "../Screenshot";
import "./index.scss";

const Software = () => {
  const { listSoftware, SVGPointIcon, firstScreenshot, secondScreenshot } =
    PG_AP_SOFTWARE_CONTENT;

  return (
    <section className="PgAP-Software">
      <div className="container">
        <h2 className="PgAP-Software__title">AMZScout Software is known for</h2>
        <div className="PgAP-Software__content">
          <div className="PgAP-Software__wrapImage">
            <Screenshot
              listPictures={firstScreenshot}
              classes="PgAP-Software__firstScreenshot"
            />
            <Screenshot
              listPictures={secondScreenshot}
              classes="PgAP-Software__secondScreenshot"
            />
          </div>
          <ul className="PgAP-Software__list">
            {listSoftware.map((text, i) => (
              <li key={i} className="PgAP-Software-item">
                <img src={SVGPointIcon} alt="." />
                <p className="PgAP-Software-item__text">{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Software;
