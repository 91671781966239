import SVGOne from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-1.svg"
import SVGTwo from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-2.svg"
import SVGThree from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-3.svg"
import SVGFour from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-4.svg"
import SVGFive from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-5.svg"
import SVGSix from "../../../assets/images/vector/pages/affiliate-program/PartnersAre/affiliate-program-PartnersAre-6.svg"

const PG_AP_PARTNERS_ARE_CONTENT = [
  {
    SVGImg: SVGOne,
    title: "Website owners",
    text: "Monetize your website by adding promo banners and affiliate links whenever possible",
  },
  {
    SVGImg: SVGTwo,
    title: "Bloggers",
    text: "Create affiliate blogs and articles about how to use AMZScout",
  },
  {
    SVGImg: SVGThree,
    title: "Influencers",
    text: "Share your experience of using AMZScout with your audience, providing them with special AMZScout offers through your affiliate links",
  },
  {
    SVGImg: SVGFour,
    title: "YouTubers",
    text: "Publish videos reviewing your experience with AMZScout",
  },
  {
    SVGImg: SVGFive,
    title: "Marketers",
    text: "Make use of your skills and direct traffic to the AMZScout website. Run paid search, Facebook ads or email campaigns and verify which work best for you.",
  },
  {
    SVGImg: SVGSix,
    title: "eCommerce Gurus",
    text: "Share your knowledge with your students and suggest AMZScout, using your affiliate link",
  },
]

export default PG_AP_PARTNERS_ARE_CONTENT
