import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import PG_AP_INTRO_CONTENT from "./index.content";
import "./index.scss";

const Intro = () => {
  const { dataLink, SVGPointIcon, SVGImg } = PG_AP_INTRO_CONTENT;

  return (
    <section className="PgAP-Intro">
      <div className="container">
        <div className="PgAP-Intro__content">
          <div className="PgAP-Intro__info">
            <h1 className="PgAP-Intro__title">
              Join and make $10,000/mo as AMZScout Affiliate!
            </h1>
            <ul className="PgAP-Intro__items">
              <li key={0} className="PgAP-Intro__item">
                <img src={SVGPointIcon} alt="." />
                <p>
                  Grow your reach with our <b>1,000,000 subscribers</b>
                </p>
              </li>
              <li key={1} className="PgAP-Intro__item">
                <img src={SVGPointIcon} alt="." />
                <p>
                  Host a webinar and make <b>up to $60,000 in one day!</b>
                </p>
              </li>
              <li key={2} className="PgAP-Intro__item">
                <img src={SVGPointIcon} alt="." />
                <p>
                  <b>Make up to $10,000 every month</b> selling a top-3 Amazon
                  seller's tool
                </p>
              </li>
            </ul>
            <TrackedLink
              category={dataLink.category}
              action={dataLink.text}
              class="PgAP-Intro__link"
              path={dataLink.url}
            >
              {dataLink.text}
            </TrackedLink>
          </div>
          <img className="PgAP-Intro__img" src={SVGImg} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default Intro;
