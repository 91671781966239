import SVGPointIcon from "../../../assets/images/vector/components/common/mainPage-home_01.svg"

import deskImgOneWebp from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1.webp"
import deskImgOneWebp2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@2x.webp"
import deskImgOnePng from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1.png"
import deskImgOnePng2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@2x.png"
import mobImgOneWebp from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@mob.webp"
import mobImgOneWebp2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@mob2x.webp"
import mobImgOnePng from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@mob.png"
import mobImgOnePng2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-1@mob2x.png"

import deskImgTwoWebp from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2.webp"
import deskImgTwoWebp2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@2x.webp"
import deskImgTwoJpg from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2.jpg"
import deskImgTwoJpg2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@2x.jpg"
import mobImgTwoWebp from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@mob.webp"
import mobImgTwoWebp2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@mob2x.webp"
import mobImgTwoJpg from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@mob.jpg"
import mobImgTwoJpg2x from "../../../assets/images/raster/pages/affiliate-program/Software/affiliate-program-Software-2@mob2x.jpg"

const PG_AP_SOFTWARE_CONTENT = {
  listSoftware: [
    "The most accurate AI-powered sales estimation algorithm",
    "Exhaustive historical data on sales, rankings and pricing",
    "Lightning fast interface",
    "Features for identifying growing trends",
    "Support of 10 marketplaces",
    "Features for spying on your competitors",
  ],
  SVGPointIcon,
  firstScreenshot: {
    deskImgWebp: deskImgOneWebp,
    deskImgWebp2x: deskImgOneWebp2x,
    deskImgPng: deskImgOnePng,
    deskImgPng2x: deskImgOnePng2x,
    mobImgWebp: mobImgOneWebp,
    mobImgWebp2x: mobImgOneWebp2x,
    mobImgPng: mobImgOnePng,
    mobImgPng2x: mobImgOnePng2x,
  },
  secondScreenshot: {
    deskImgWebp: deskImgTwoWebp,
    deskImgWebp2x: deskImgTwoWebp2x,
    deskImgPng: deskImgTwoJpg,
    deskImgPng2x: deskImgTwoJpg2x,
    mobImgWebp: mobImgTwoWebp,
    mobImgWebp2x: mobImgTwoWebp2x,
    mobImgPng: mobImgTwoJpg,
    mobImgPng2x: mobImgTwoJpg2x,
  },
}
export default PG_AP_SOFTWARE_CONTENT
