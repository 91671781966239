import React from "react";
import { TESTIMONIALS_VIDEOS_LIST_PG_AP_CONTENT } from "../../common/TestimonialsVideos/index.content";
import TestimonialsVideos from "../../common/TestimonialsVideos";
import "./index.scss";

const Testimonials = ({ setUrlYoutubeModal }) => (
  <section className="PgAP-Testimonials">
    <h2 className="PgAP-Testimonials__title">
      Watch famous sellers & bloggers review AMZScout affiliate program
    </h2>
    <TestimonialsVideos
      setUrlYoutubeModal={setUrlYoutubeModal}
      customContent={TESTIMONIALS_VIDEOS_LIST_PG_AP_CONTENT}
    />
  </section>
);

export default Testimonials;
