import deskImgWebp from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp.webp"
import deskImgWebp2x from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@2x.webp"
import deskImgPng from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp.png"
import deskImgPng2x from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@2x.png"
import mobImgWebp from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@mob.webp"
import mobImgWebp2x from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@mob2x.webp"
import mobImgPng from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@mob.png"
import mobImgPng2x from "../../../assets/images/raster/pages/affiliate-program/AffiliateApp/affiliate-program-AffiliateApp@mob2x.png"

const PG_AP_AFFILIATE_APP_CONTENT = {
  deskImgWebp: deskImgWebp,
  deskImgWebp2x: deskImgWebp2x,
  deskImgPng: deskImgPng,
  deskImgPng2x: deskImgPng2x,
  mobImgWebp: mobImgWebp,
  mobImgWebp2x: mobImgWebp2x,
  mobImgPng: mobImgPng,
  mobImgPng2x: mobImgPng2x,
}
export default PG_AP_AFFILIATE_APP_CONTENT
