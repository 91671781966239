import React from "react";
import PropTypes from "prop-types";

const Screenshot = ({ classes, listPictures }) => {
  const {
    deskImgWebp,
    deskImgWebp2x,
    deskImgPng,
    deskImgPng2x,
    mobImgWebp,
    mobImgWebp2x,
    mobImgPng,
    mobImgPng2x,
  } = listPictures;

  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 992px)"
        srcSet={`${deskImgWebp} 1x, ${deskImgWebp2x} 2x`}
      />
      <source
        media="(min-width: 992px)"
        srcSet={`${deskImgPng} 1x, ${deskImgPng2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 991px)"
        srcSet={`${mobImgWebp} 1x, ${mobImgWebp2x} 2x`}
      />
      <source
        media="(max-width: 991px)"
        srcSet={`${mobImgPng} 1x, ${mobImgPng2x} 2x`}
      />
      <img src={deskImgPng} className={classes} alt="img" loading="lazy" />
    </picture>
  );
};
Screenshot.propTypes = {
  listPictures: PropTypes.object,
  classes: PropTypes.string,
};

export default Screenshot;
